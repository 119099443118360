@import "../../styles/variables.scss";
@import "../../styles/colors.scss";

.projects {
  .skill-container {
    display: flex;
    justify-content: space-around;

    margin-top: -10rem;

    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }
    .skill-card {
      background-color: $secondaryTextColor;
      border: 1px solid $borderColor;
      border-radius: 1rem;
      padding: 2.5rem;
      min-width: 300px;
      max-width: 400px;
      box-shadow: 0 5px 5px 0 rgba(233, 240, 243, 0.5), 0 0 0 1px$borderColor;

      @media only screen and (max-width: $breakpoint-tablet) {
        margin: 0 auto;
        margin-bottom: 5%;
        width: 90%;
      }
      i {
        color: $primaryColor;
      }

      .title {
        color: $primaryColor;
        font-size: 1.75rem;
        font-weight: bold;
        padding-top: 1rem;
      }

      p {
        font-size: 1.25rem;
      }

      .trancate-overflow {
        --lh: 1.4rem;
        line-height: var(--lh);
        --max-lines: 3;
        position: relative;
        max-height: calc(var(--lh) * var(--max-lines));
        overflow: hidden;
        padding-right: 1rem;
      }
      .truncate-overflow::before {
        position: absolute;
        content: "...";
        /* tempting... but shows when lines == content */
        /* top: calc(var(--lh) * (var(--max-lines) - 1)); */

        /*
        inset-block-end: 0;
        inset-inline-end: 0;
        */
        bottom: 0;
        right: 0;
      }
      .truncate-overflow::after {
        content: "";
        position: absolute;
        /*
        inset-inline-end: 0;
        */
        right: 0;
        /* missing bottom on purpose*/
        width: 1rem;
        height: 1rem;
        background: white;
      }
      .tagline {
        line-height: 1.5;
      }
    }
  }
  .projects-title {
    margin-top: 5rem;
    color: $primaryTextColor;
    font-weight: bold;
    font-size: 2rem;
  }
  p {
    font-size: 1.25rem;
  }
  .project-container {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    overflow-x: hidden;
    margin: 0 auto;
    max-width: 1300px;

    .project-card {
      min-width: 300px;
      max-width: 30%;
      min-height: 20rem;
      max-height: 20rem;
      border-radius: 1rem;
      margin-bottom: 5%;
      border: 1px solid $borderColor;
      box-shadow: 0 5px 5px 0 rgba(233, 240, 243, 0.5), 0 0 0 1px$borderColor;
      position: relative;
      overflow: hidden;
      color: $secondaryTextColor;

      &:hover {
        figcaption {
          z-index: 2;
          opacity: 1;
        }
        .overlay {
          opacity: 1;
          z-index: 1;
        }
      }
      img {
        transform: scale(1);
        transition: 0.25s ease-in-out;
        margin-top: 5%;
        width: 100%;
        height: auto;
        padding: 10%;
      }

      .lbtc {
        height: 100%;
        width: auto;
        margin: 0;
      }
      figcaption {
        display: flex;
        flex-direction: column;
        position: absolute;
        padding: 2rem;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: 0.45s ease-in-out;
        h1 {
          font-weight: bold;
          // margin-top: 0;
        }
        p {
          font-size: 1rem;
        }
        a {
          text-decoration: none;
        }
        .code-link {
          color: $secondaryTextColor;
          width: 45%;
          margin: 0 auto;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $secondaryTextColor;
            visibility: hidden;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: all 0.3s ease-in-out 0s;
            transition: all 0.3s ease-in-out 0s;
            text-underline-position: below;
          }

          &:hover {
            color: $primaryColor;
            text-underline-position: below;
          }

          &:hover:before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            text-underline-position: below;
            color: #8b43ff;
          }
        }
        .view-project {
          color: $primaryColor;
          border: 2px solid $primaryColor;
          border-radius: 1.5rem;
          font-size: 1.25rem;
          font-weight: bold;
          width: 70%;
          margin: 0 auto;
          margin-bottom: 1rem;
          padding: 0.5rem;
          &:hover {
            background-color: $primaryColor;
            color: $secondaryTextColor;
          }
        }
      }
      .overlay {
        background-color: $primaryTextColor;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: 0.45s ease-in-out;
      }
    }
  }
}
