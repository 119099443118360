@import "../../styles/variables.scss";
@import "../../styles/colors.scss";

.about {
  background-color: $primaryColor;

  .about-container {
    padding: 6rem 0;
    color: $secondaryTextColor;
    width: 50%;
    min-width: 350px;
    margin: 0 auto;

    .about-title {
      font-weight: 900;
      font-size: 2rem;
    }

    .about-text {
      line-height: 1.5;
      font-size: 1.6rem;
      font-weight: 400;
      padding-bottom: 6rem;
    }
  }
}
