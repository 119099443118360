@import "../../styles/variables.scss";
@import "../../styles/colors.scss";

.footer-container {
  display: flex;
  flex-direction: column;
  background-color: $primaryColor;
  position: relative;
  color: $secondaryTextColor;
  padding-bottom: 2.5%;

  .link-top {
    margin: 0 auto;
    margin-top: 5%;
    max-width: 67px;
    height: auto;
    cursor: pointer;
    border-radius: 50%;
    padding: 0.5%;

    i {
      color: $secondaryTextColor;
      font-size: 4rem;
    }
    &:hover {
      background-color: $primaryTextColor;
    }
  }
  .sign-off {
    font-size: 2rem;
    width: 30%;
    min-width: 300px;
    margin: 5% auto;
  }

  .social-media-links {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 2rem auto;
    min-width: 25%;

    @media only screen and (max-width: $breakpoint-tablet) {
      min-width: 300px;
    }

    i {
      border-radius: 0.3rem;
      color: $primaryTextColor;
      font-size: 3rem;
      width: 110%;
      height: auto;

      &:hover {
        background-color: $secondaryTextColor;
      }
    }
  }
}
