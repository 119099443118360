@import "../styles/variables.scss";
@import "../styles/colors.scss";

.contact-section {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;

  .contact-top {
    display: flex;
    flex-direction: column;
    text-align: center;

    .avatar-container {
      width: 150px;
      margin: -10% auto 0 auto;

      img {
        width: inherit;
        height: inherit;
      }
    }

    h1 {
      color: $primaryTextColor;
      margin: 1% auto 0 auto;
      font-size: 2rem;
    }
  }

  .contact-bottom {
    display: flex;
    margin: 5% auto;
    width: 60%;
    max-width: 60%;
    @media only screen and (max-width: $breakpoint-tablet) {
      width: 90%;
      max-width: 90%;
    }
    form {
      width: 100%;
      color: $formText;
    }
    label {
      display: flex;
      flex-direction: column;
      font-size: 1.5rem;
    }
    input {
      height: 2.5rem;
      line-height: 1.5rem;
      font-size: 1.5rem;
      padding: 2%;
      border: 2px solid rgb(238, 238, 238);
    }
    textarea {
      resize: vertical;
      border: 2px solid rgb(238, 238, 238);
      line-height: 2rem;
      font-size: 1.5rem;
    }
    .errorMsg {
      font-size: 1rem;
      color: salmon;
    }
    .successMsg {
      font-size: 2rem;
      color: springgreen;
      text-align: center;
    }

    .name-email-input {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5%;
      @media only screen and (max-width: $breakpoint-tablet) {
        flex-direction: column;
      }
      label {
        width: 45%;
        @media only screen and (max-width: $breakpoint-tablet) {
          width: 100%;
        }
      }
    }

    .submit-button {
      display: flex;
      button {
        margin: 8% auto;
        padding: 2% 5%;
        width: 40%;
        min-width: 220px;
        font-size: 3rem;
        border-radius: 1.5rem;
        border: 2px solid $primaryColor;
        color: $primaryColor;
        cursor: pointer;
        white-space: nowrap;

        &:hover {
          background-color: $primaryColor;
          color: $secondaryTextColor;
        }
      }
    }
  }
}
