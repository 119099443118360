.App {
  text-align: center;
}

/* text color -- color: #141c3a, #fff */
/* background color -- #7510F7 */

.navbar {
  display: flex;
}
